.component {
    padding-top: 4rem;
    padding-bottom: 4rem;
    max-width: 1440px;
    margin: 0 auto;

    @media (max-width: 1040px) {
        padding-top: 1rem;
    }

    .imageBox {
        cursor: pointer;
    }

    .image { 
        display: block; 
        height: auto; 
        margin: 0 auto; 
        pointer-events: none; 
        width: 100%;
    }

    p { 
        margin: 0 auto; 
        max-width: 812px; 
        padding: 32px 56px 32px; 
        text-align: center;

        @media (max-width: 480px) { 
            padding: 32px 0 32px; 
        }
    }

    .timeline { 
        margin-bottom: 48px; 
        overflow: hidden;

        .timelineWrapper { 
            display: flex; 
            width: 100%;

            .timelineItem {
                align-items: center; 
                display: flex; 
                flex-shrink: 0; 
                justify-content: center; 
                padding: 0 56px; 
                position: relative;

                @media (min-width: 480px) { 
                    padding: 0 80px; 
                }

                .time {
                    color: var(--text-color); 
                    display: block; 
                    margin: 0; 
                    text-align: center; 
                    transition: color 300ms ease; 
                    width: 150px;
                    font-weight: bold;
                }

                .line { 
                    background-color: rgb(var(--secondary-light)); 
                    height: 4px; 
                    left: 0; 
                    position: absolute; 
                    transform: translateX(-50%); 
                    width: 80px;

                    @media (min-width: 480px) { 
                        width: 96px; 
                    }

                    .lineInner { 
                        background-color: rgb(var(--primary-light));
                        height: 100%; 
                    }

                    &.complete { 
                        opacity: 0.2; 
                        transition: opacity 300ms ease; 
                    }
                }
            }
        }
    }        
}

.modalImage {
   margin-top: 40px;
   display: block;
}
