.mainTitle {
    font-family: var(--secondary-font);
    font-weight: 700;
    font-size: 88px;
    line-height: 96px;
    text-align: center;
    text-transform: uppercase;
    padding-top: 90px;
    margin-left: auto;
    margin-right: auto;
    max-width: 940px;

    @media (max-width: 575px) {
        padding-top: 32px;
        font-size: 42px;
        line-height: 120%;
    }

    span {
        color: rgb(var(--primary-main));
    }
}

.secondTitle {
    font-size: 32px;
    line-height: 1;
    margin-top: 24px;

    @media (max-width: 575px) {
        font-size: 20px;
    }
}

.mainP {
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 150%;
    text-align: center;
    max-width: 540px;
    margin: 24px auto 0;
    font-weight: 700;

    + .mainP {
        margin-top: 12px;
        font-size: 18px;
    }

    @media (max-width: 575px) {
        max-width: 280px;
        font-size: 16px;
        line-height: 150%;
        margin-top: 16px;

        + .mainP {
           font-size: 14px;
        }

        > span {
            display: block;
        }
    }
}

.benefit {
    display: flex;
    justify-content: space-around;
    max-width: var(--max-width);
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 100px;
    margin-top: 56px;
    flex-wrap: wrap;

    @media (max-width: 575px) {
        row-gap: 24px;
        margin-top: 32px;
        margin-bottom: 48px;
    }

    li {
        display: flex;
        gap: 12px;
        align-items: center;
        font-size: 20px;

        @media (max-width: 575px) {
            flex-direction: column;
            flex-basis: 20%;
            font-size: 12px;

            > span > span {
                display: block;
            }
        }
    }
}

.subTitle {
    font-weight: 700;
    font-size: 54px;
    line-height: 78px;
    font-family: var(--secondary-font);
    margin-bottom: 60px;

    @media (max-width: 575px) {
        font-size: 30px;
        line-height: 35px;
        margin-bottom: 32px;
    }
}

.workBox {
    display: grid;
    grid-gap: 40px;
    padding: 0 24px;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);

    @media (max-width: 1040px) {
        grid-template-columns: 1fr;
    }

    @media (max-width: 575px) {
        padding: 0;
        grid-gap: 16px;
    }
}

.workElem {
    border-radius: 20px;
    border: 1px solid rgb(var(--secondary-light));
    background-color: rgb(var(--secondary-dark));
    padding: 54px 40px;
    text-align: center;

    @media (max-width: 575px) {
        border: none;
        padding: 32px 8px;
    }

    h3 {
        font-weight: 400;
        font-size: 32px;
        line-height: 38px;
        margin-bottom: 32px;

        @media (max-width: 575px) {
            font-size: 25px;
            line-height: 29px;
            margin-bottom: 16px;
        }
    }

    > p {
        margin-bottom: 40px;

        @media (max-width: 575px) {
            font-size: 14px;
            line-height: 150%;
            margin-bottom: 24px;
        }

        span {
            display: block;

            @media (max-width: 575px) {
                display: inline;
            }
        }
    }
}

.workArrow {
    display: flex;
    justify-content: space-around;
    margin-bottom: 32px;

    @media (max-width: 575px) {
        margin-bottom: 24px;
    }

    svg {
        transform: rotate(90deg);
    }
}

.workIcon {
    display: flex;
    justify-content: center;
    margin-bottom: 32px;

    @media (max-width: 575px) {
        margin-bottom: 16px;
    }
}

.stepBlock {
    display: flex;
    gap: 40px;

    @media (max-width: 575px) {
        gap: 16px;
    }

    @media (min-width: 576px) {
        &.second svg {
            transform: scale(1.2);
            margin-bottom: 24px;
        }
    }
}

.stepItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;

    .stepIcon {
        max-width: 100%;
    }

    @media (max-width: 575px) {
        gap: 16px;

        p {
            font-size: 12px;
            line-height: 150%;
        }
    }

    h4 {
        font-style: normal;
        font-weight: 400;
        font-size: 25px;
        line-height: 29px;

        @media (max-width: 575px) {
            font-size: 20px;
            line-height: 23px;
        }
    }
}

.points {
    margin-top: 120px;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    @media (max-width: 575px) {
        margin-top: 80px;
    }

    h2 {
        font-family: var(--secondary-font);
        font-weight: 700;
        font-size: 40px;
        line-height: 46px;
        margin-bottom: 48px;

        @media (max-width: 575px) {
            font-size: 30px;
            line-height: 35px;
            margin-bottom: 24px;
        }
    }

    h5 {
        margin-top: 40px;
        margin-bottom: 40px;
        font-weight: 400;
        font-size: 25px;
        line-height: 29px;

        @media (max-width: 575px) {
            font-size: 20px;
            line-height: 23px;
            margin-top: 16px;
            margin-bottom: 16px;
        }
    }

    p {
        max-width: 440px;

        @media (max-width: 575px) {
            font-size: 14px;
            line-height: 150%;
        }

        span {
            display: block;
        }
    }
}

.pointArrow {
    position: absolute;
    transform: rotate(45deg);
    left: 0;
    top: -25%;
}

.formats {
    margin-top: 100px;

    @media (max-width: 575px) {
        margin-top: 48px;
    }

    h2 {
        font-family: var(--secondary-font);
        font-weight: 700;
        font-size: 40px;
        line-height: 46px;
        margin-bottom: 48px;

        @media (max-width: 575px) {
            margin-bottom: 24px;
            font-size: 30px;
            line-height: 35px;
        }
    }
}

.formatBox {
    display: flex;
    gap: 16px;
    justify-content: center;
    padding: 0 24px;
    flex-wrap: wrap;

    @media (max-width: 575px) {
        padding: 0;
    }
}

.formatItem {
    min-width: 180px;
    background-color: rgb(var(--secondary-light));
    border-radius: 20px;
    padding: 72px 32px 16px;
    background-repeat: no-repeat;
    background-position: center 8px;
    background-size: 28%;

    @media (max-width: 575px) {
        min-width: 100px;
        padding: 44px 8px 16px;
        background-size: 30%;
    }

    h5 {
        font-weight: 400;
        font-size: 32px;
        line-height: 38px;
        text-align: center;
        margin-bottom: 8px;

        @media (max-width: 575px) {
            font-size: 20px;
            line-height: 23px;
            margin-bottom: 8px;
        }
    }

    p {
        @media (max-width: 575px) {
            font-size: 8px;
            line-height: 9px;
        }
    }

    &.X2 {
        background-image: url("/bets/icons/x2.png");
        background-position: center 20px;
    }

    &.X3 {
        background-image: url("/bets/icons/x3.png");
    }

    &.X4 {
        background-image: url("/bets/icons/x4.png");
    }

    &.X5 {
        background-image: url("/bets/icons/x5.png");
    }

    &.Decagon {
        background-image: url("/bets/icons/x10.png");
    }

    &.Polygon {
        background-image: url("/bets/icons/polygon.png");
    }

    &.Unlimited {
        background-image: url("/bets/icons/unlimited.png");
    }
}

.listItem {
    position: relative;
    padding-left: 24px;
    padding-bottom: 0.5rem;

    &:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(calc(-50% - 0.25rem));
        width: 0.5rem;
        height: 0.5rem;
        border-radius: 50%;
        background-color: rgb(var(--primary-dark));
    }

    @media (max-width: 1040px) {
        font-size: 1rem;
    }
}

.grayBlock {
    margin-top: 100px;

    @media (max-width: 575px) {
        margin-top: 48px;
    }

    h3 {
        font-family: var(--secondary-font);
        font-weight: 700;
        font-size: 40px;
        line-height: 46px;
        margin-bottom: 48px;

        @media (max-width: 575px) {
            margin-bottom: 24px;
            font-size: 30px;
            line-height: 35px;
        }
    }
}

.grayPools {
    display: grid;
    gap: 16px;
    max-width: var(--max-width);
    margin-left: auto;
    margin-right: auto;
    padding: 0 1.5rem;
    grid-template-columns: 1fr 1fr 1fr;

    &.cl-1 {
        grid-template-columns: repeat(4, 1fr);

        > *:first-child {
            grid-column-start: 2;
            grid-column-end: 4;
        }
    }

    &.cl-2 {
        grid-template-columns: 1fr 1fr;
    }

    @media (max-width: 1040px) {
        padding: 0 1.5rem;
        grid-template-columns: 1fr 1fr;
    }

    @media (max-width: 575px) {
        &, &.cl-2, &.cl-1 {
            grid-template-columns: 1fr;

            > *:first-child {
                grid-column-start: 1;
            }
        }
    }

    .grayPool {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;

        > *:first-child {
            width: 100%;
            flex: 1;
        }
    }    
}

.smallCarousel {
    margin-bottom: 48px;
}

.championsList {
    --small-carousel-box-gap: 16px;
}

.poolsBlock {
    margin-top: 40px;
}

.noItem {
    min-height: 280px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.grayLink {
    width: 33.33%;
    min-width: 33.33%;

    @media (max-width: 1040px) {
        width: 46%;
        min-width: 46%;
    }

    @media (max-width: 480px) {
        width: 100%;
        min-width: 100%;
    }
}

.smallHeader {
    text-align: center;
    font-size: 18px;
    margin-bottom: 16px;

    .item {
        padding-left: 2.25rem;
        background-repeat: no-repeat;
        background-position: 0.25rem center;
    
        &.f {
            background-image: url("/bets/sport-1.svg");
            background-size: 1.75rem;
        }
    
        &.b {
            background-image: url("/bets/sport-2.svg");
            background-size: 1.125rem;
        }
    
        &.t {
            background-image: url("/bets/sport-3.svg");
            background-size: 1.125rem;
        }
    }
}

.smallItem {
    height: 40px;
    width: 84px;
    cursor: pointer;
    background-color: rgb(var(--secondary-light));
    border-radius: 16px;
    flex-shrink: 0;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 84%;

    &:hover {
        background-color: rgba(var(--primary-dark), 0.3);
    }

    &.activeCam {
        background-color: rgba(var(--primary-dark), 0.5);
    }

    &.bundesliga_germany {
        background-image: url("/championship/bundesliga_germany.png");
    }

    &.champions_league {
        background-image: url("/championship/champions_league.png");
    }

    &.conference_league {
        background-image: url("/championship/conference_league.png");
    }

    &.efl_championship {
        background-image: url("/championship/efl_championship.png");
    }

    &.euro {
        background-image: url("/championship/euro.png");
    }

    &.europe_league {
        background-image: url("/championship/europe_league.png");
    }

    &.laliga_spain {
        background-image: url("/championship/laliga_spain.png");
    }

    &.ligue1_france {
        background-image: url("/championship/ligue1_france.png");
    }

    &.mls_usa {
        background-image: url("/championship/mls_usa.png");
    }

    &.premier_league_england {
        background-image: url("/championship/premier_league_england.png");
    }

    &.roshn_saudi {
        background-image: url("/championship/roshn_saudi.png");
    }

    &.serie_a_italy {
        background-image: url("/championship/serie_a_italy.png");
    }

    &.uefa_nations {
        background-image: url("/championship/uefa_nations.png");
    }
}
